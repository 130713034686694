import nightOwl from "@theme-ui/prism/presets/night-owl.json"
import * as tokens from "../../build/js"
import { gridGlobals } from "./globals/grid"
import { linkGlobals } from "./globals/link"
import { listGlobals } from "./globals/list"
import { sectionHeadingGlobals } from "./globals/section-heading"

const theme = {
  colors: {
    text: tokens.systemForegroundDefault,
    textInverse: tokens.systemForegroundInverse,
    background: tokens.systemBackgroundDefault,
    backgroundInverse: tokens.systemBackgroundInverseDefault,
    backgroundHighContrast: tokens.systemBackgroundHighContrast,
    primary: tokens.systemPrimaryMuted,
    primarySubtle: tokens.systemPrimarySubtle,
    // secondary: tokens.systemPrimaryForeground,
    // accent: tokens.systemPrimaryForeground,
    // highlight: tokens.systemPrimaryForeground,
    muted: tokens.systemForegroundMutedDefault,
    lowContrast: tokens.systemSecondaryLowContrast,
    subtle: tokens.systemForegroundSubtleDefault,

    modes: {
      dark: {
        text: tokens.systemForegroundInverse,
        textInverse: tokens.systemForegroundDefault,
        background: tokens.systemBackgroundInverseDefault,
        backgroundInverse: tokens.systemBackgroundDefault,
        primary: tokens.systemPrimarySubtle,
        // secondary: tokens.systemPrimaryForeground,
        // accent: tokens.systemPrimaryForeground,
        // highlight: tokens.systemPrimaryForeground,
        muted: tokens.systemForegroundMutedInverse,
        lowContrast: tokens.systemForegroundLowContrastInverse,
        subtle: tokens.systemForegroundSubtleInverse,
      },

      blue: {
        text: tokens.systemPrimaryForeground,
        background: tokens.systemPrimaryBackground,
        primary: tokens.systemPrimaryForeground,
        // secondary: tokens.systemPrimaryForeground,
        // accent: tokens.systemPrimaryForeground,
        // highlight: tokens.systemPrimaryForeground,
        muted: tokens.systemPrimaryMuted,
        lowContrast: tokens.systemPrimaryLowContrast,
        subtle: tokens.systemPrimarySubtle,
      },

      purple: {
        text: tokens.systemSecondaryForeground,
        background: tokens.systemSecondaryBackground,
        primary: tokens.systemSecondaryForeground,
        // secondary: tokens.systemPrimaryForeground,
        // accent: tokens.systemPrimaryForeground,
        // highlight: tokens.systemPrimaryForeground,
        muted: tokens.systemSecondaryMuted,
        lowContrast: tokens.systemSecondaryLowContrast,
        subtle: tokens.systemSecondarySubtle,
      },

      white: {
        background: tokens.systemBackgroundHighContrast,
      },
    },
  },

  space: [
    tokens.spacing0,
    tokens.spacing1,
    tokens.spacing2,
    tokens.spacing3,
    tokens.spacing4,
    tokens.spacing5,
    tokens.spacing6,
    tokens.spacing7,
  ],

  radii: {
    default: ["0.25rem", "0.25rem", tokens.radiusDefault, tokens.radiusDefault],
    large: ["0.5rem", "0.5rem", tokens.radiusLarge, tokens.radiusLarge],
    pill: tokens.radiusPill,
  },

  fonts: {
    body: `"${tokens.fontFamilyDefault}", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    heading: "inherit",
    monospace: `"${tokens.fontFamilyMono}", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
    condensed: `"${tokens.fontFamilyCondensed}", -apple-system, "Arial Narrow", sans-serif`,
  },

  fontSizes: [
    tokens.fontSize0,
    tokens.fontSize1,
    tokens.fontSize2,
    tokens.fontSize3,
    tokens.fontSize4,
    tokens.fontSize5,
    tokens.fontSize6,
    tokens.fontSize7,
  ],

  fontWeights: {
    body: tokens.fontWeightRegular,
    heading: tokens.fontWeightMedium, // Not using this but need it here for a valid theme spec.
    light: tokens.fontWeightLight,
    normal: tokens.fontWeightRegular,
    medium: tokens.fontWeightMedium,
    semiBold: tokens.fontWeightSemiBold,
    bold: tokens.fontWeightBold,
  },

  lineHeights: {
    flat: tokens.lineHeight100,
    heading: tokens.lineHeight125,
    body: tokens.lineHeight150,
  },

  letterSpacings: [
    tokens.letterSpacing0,
    tokens.letterSpacing1,
    tokens.letterSpacing2,
    tokens.letterSpacing3,
    tokens.letterSpacing4,
    tokens.letterSpacing5,
    tokens.letterSpacing6,
  ],

  shadows: {
    shadow01: tokens.elevation1,
    shadow02: tokens.elevation2,
    shadow03: tokens.elevation3,
    shadow04: tokens.elevation4,
    shadow05: tokens.elevation5,
    shadow06: tokens.elevation6,
  },

  breakpoints: [
    tokens.breakpointSm,
    tokens.breakpointMd,
    tokens.breakpointLg,
    tokens.breakpointXl,
  ],

  sizes: {
    sm: tokens.sizeSm,
    md: tokens.sizeMd,
    lg: tokens.sizeLg,
    xl: tokens.sizeXl,
    container: tokens.sizeXl,
  },

  layout: {
    container: {
      maxWidth: "container",
      px: [4, 4, 6, 6],
    },

    mainContentFooter: {
      pt: [5, 5, 6, 6],
    },
  },

  grids: {
    default: {
      ...gridGlobals,
      gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
    },

    cards: {
      ...gridGlobals,
      gridTemplateColumns: "repeat(auto-fill, minmax(30.5rem, 1fr))",
      rowGap: 4,
    },
  },

  text: {
    body: {
      fontSize: [1, 1, 2, 2],
    },

    small: {
      fontSize: 1,
    },

    paragraph: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      mt: 0,
      mx: 0,
      mb: [4, 4, 5, 5],
    },

    lead: {
      variant: "text.paragraph",
      fontSize: [2, 3, 4, 4],
    },

    caption: {
      fontSize: [0, 0, 1, 1],
      color: "textMuted",
    },

    heading: {
      lineHeight: "heading",
      mt: 0,
    },

    h1: {
      variant: "text.heading",
      fontWeight: "medium",
      fontSize: [6, 6, 7, 7],
      letterSpacing: 0,
      mb: [1, 2, 3, 3],
    },

    h2: {
      variant: "text.heading",
      fontWeight: "medium",
      fontSize: [5, 5, 6, 6],
      letterSpacing: 1,
      mb: [1, 2, 3, 3],
    },

    h3: {
      variant: "text.heading",
      fontWeight: "medium",
      fontSize: [3, 3, 4, 4],
      letterSpacing: 2,
      mb: [1, 1, 2, 2],
    },

    h4: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: [2, 2, 3, 3],
      letterSpacing: 3,
      mb: [1, 1, 2, 2],
    },

    h5: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: [1, 1, 2, 2],
      letterSpacing: 4,
      mb: [1, 1, 2, 2],
    },

    h6: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: 1,
      letterSpacing: 5,
      mb: [1, 1, 2, 2],
    },

    sectionHeading: {
      small: {
        ...sectionHeadingGlobals,
        fontSize: 1,
      },

      large: {
        ...sectionHeadingGlobals,
        fontSize: [1, 1, 2, 2],
      },
    },

    timeStamp: {
      fontFamily: "monospace",
      fontSize: 0,
      color: "muted",
      textTransform: "uppercase",
    },

    logoType: {
      fontFamily: "condensed",
      fontWeight: "semiBold",
      fontSize: 1,
      lineHeight: "heading",
      letterSpacing: 6,
      textTransform: "uppercase",
    },
  },

  links: {
    default: {
      ...linkGlobals,
    },
  },

  lists: {
    default: {
      variant: "text.paragraph",
      listStyleType: '"- "',
      pl: [3, 3, 4, 4],
      mt: 0,

      li: {
        mb: [2, 2, 4, 4],

        "&:last-child": {
          mb: 0,
        },
      },
    },

    small: {
      variant: "text.small",
      pl: [1, 1, 2, 2],

      li: {
        mb: [1, 1, 2, 2],
      },
    },

    unstyled: {
      ...listGlobals.unstyled,
    },

    smallUnstyled: {
      variant: "lists.small",
      ...listGlobals.unstyled,
    },
  },

  cards: {
    primary: {
      backgroundColor: "cardBackground",
      borderRadius: "default",
      mb: 4,
    },
  },

  eyebrow: {
    fontSize: 0,
    color: "muted",
    textTransform: "uppercase",
    letterSpacing: 6,
  },

  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontVariantLigatures: "discretionary-ligatures",
      fontFeatureSettings: "dlig",
      color: "text",
      height: "100%",
      webkitFontSmoothing: "antialiased",

      body: {
        variant: "text.body",
        height: "100%",
      },

      a: {
        variant: "links.default",
      },

      // "*:link, *:visited, *:hover, *:active, *:focus": {
      //   transition:
      //     "color .15s linear, background-color .15s linear, background-position .3s ease-out, border-color .15s linear, opacity .15s linear, fill .15s linear",
      // },

      p: {
        variant: "text.paragraph",
      },

      ul: {
        variant: "lists.default",
      },

      dl: {
        my: 0,
      },

      dd: {
        ml: 0,
      },

      paragraph: {
        variant: "text.paragraph",
      },

      h1: {
        variant: "text.h1",
      },

      h2: {
        variant: "text.h2",
      },

      h3: {
        variant: "text.h3",
      },

      h4: {
        variant: "text.h4",
      },

      h5: {
        variant: "text.h5",
      },

      h6: {
        variant: "text.h6",
      },

      small: {
        variant: "text.small",
      },

      // table: {
      //   borderCollapse: "collapse",
      //   mb: 4,

      //   thead: {
      //     th: {
      //       verticalAlign: "bottom",
      //       borderBottom: theme => `2px solid ${theme.colors.gray300}`,
      //     },
      //   },

      //   td: {
      //     padding: 2,
      //     verticalAlign: "top",
      //     borderTop: theme => `1px solid ${theme.colors.gray300}`,
      //   },
      // },

      figure: {
        mt: 0,
        mx: 0,
        mb: 6,
      },
    },

    hr: {
      borderBottom: "1px solid",
      borderColor: "divider",
    },

    blockquote: {
      borderLeft: "4px solid",
      borderColor: "muted",
      fontStyle: "italic",
      pl: 3,
      my: 4,
    },

    code: {
      ...nightOwl,
      borderRadius: "default",
      p: 2,
    },

    svg: {
      display: "inline-block",
      width: "100%",
      height: "auto",
      stroke: "currentColor",
      color: "currentColor",
      fill: "none",
    },
  },
}

export default theme
