/**
 * Do not edit directly
 * Generated on Wed, 13 Mar 2024 04:18:14 GMT
 */

export const systemBackgroundDefault = "#f5f6f7";
export const systemBackgroundHighContrast = "#ffffff";
export const systemBackgroundInverseDefault = "#1B1C1D";
export const systemBackgroundInverseHighContrast = "#000000";
export const systemForegroundDefault = "#303133";
export const systemForegroundMutedDefault = "#5a595e";
export const systemForegroundMutedInverse = "#cac6c7";
export const systemForegroundLowContrastDefault = "#cac6c7";
export const systemForegroundLowContrastInverse = "#5a595e";
export const systemForegroundSubtleDefault = "#e0dedd";
export const systemForegroundSubtleInverse = "#444549";
export const systemForegroundHighContrast = "#000000";
export const systemForegroundInverse = "#f5f6f7";
export const systemPrimaryBackground = "#ebf2ff";
export const systemPrimaryForeground = "#0039af";
export const systemPrimaryMuted = "#2b69e1";
export const systemPrimaryLowContrast = "#8daeed";
export const systemPrimarySubtle = "#b6cbf3";
export const systemSecondaryBackground = "#efebff";
export const systemSecondaryForeground = "#29098d";
export const systemSecondaryMuted = "#5b3cc3";
export const systemSecondaryLowContrast = "#ac97f9";
export const systemSecondarySubtle = "#cbbefb";
export const breakpointSm = "768px";
export const breakpointMd = "1044px";
export const breakpointLg = "1236px";
export const breakpointXl = "1464px";
export const colorWhite = "#ffffff"; // #fff
export const colorBlack = "#000000"; // #000
export const colorGray50 = "#f5f6f7"; // Background / #f5f6f7
export const colorGray100 = "#ecebe9"; // #ecebe9
export const colorGray200 = "#e0dedd"; // #e0dedd
export const colorGray300 = "#cac6c7"; // Light / #cac6c7
export const colorGray400 = "#B3ADAE";
export const colorGray500 = "#726f74"; // Muted Text / #726f74
export const colorGray600 = "#5a595e"; // #5a595e
export const colorGray700 = "#444549"; // #444549
export const colorGray800 = "#303133"; // Body Text / #303133
export const colorGray900 = "#1B1C1D";
export const colorBlue50 = "#ebf2ff";
export const colorBlue100 = "#d5e1f9"; // #b9d3fd
export const colorBlue200 = "#b6cbf3"; // #8cb6fc
export const colorBlue300 = "#8daeed"; // Link / #3b80f5
export const colorBlue400 = "#5d8ce7"; // #0055eb
export const colorBlue500 = "#2b69e1"; // #004bdb
export const colorBlue600 = "#004bdb"; // #003fc4
export const colorBlue700 = "#0039af"; // #0031a7
export const colorBlue800 = "#002983"; // #002487
export const colorBlue900 = "#001a57";
export const colorPurple50 = "#efebff";
export const colorPurple100 = "#e1d9fd";
export const colorPurple200 = "#cbbefb";
export const colorPurple300 = "#ac97f9";
export const colorPurple400 = "#8267de";
export const colorPurple500 = "#5b3cc3";
export const colorPurple600 = "#3d1ca8";
export const colorPurple700 = "#29098d";
export const colorPurple800 = "#1d0272";
export const colorPurple900 = "#160057";
export const elevation1 = "0 1px 1px 0 #0000001f";
export const elevation2 = "0 2px 3px -1px #00000014, 0 2px 2px -1px #0000000a";
export const elevation3 = "0 4px 6px -1px #0000000f, 0 2px 4px -1px #00000005";
export const elevation4 = "0 10px 15px -3px #0000000f, 0 4px 6px -2px #00000005";
export const elevation5 = "0 20px 25px -5px #0000000f, 0 10px 10px -5px #00000005";
export const elevation6 = "0 25px 50px -12px #00000033";
export const fontFamilyDefault = "IBM Plex Sans";
export const fontFamilyMono = "IBM Plex Mono";
export const fontFamilyCondensed = "IBM Plex Sans Condensed";
export const fontSize0 = "14px";
export const fontSize1 = "18px";
export const fontSize2 = "22px";
export const fontSize3 = "28px";
export const fontSize4 = "32px";
export const fontSize5 = "36px";
export const fontSize6 = "40px";
export const fontSize7 = "60px";
export const fontWeightLight = "300";
export const fontWeightRegular = "400";
export const fontWeightMedium = "500";
export const fontWeightSemiBold = "600";
export const fontWeightBold = "700";
export const lineHeight100 = "1";
export const lineHeight125 = "1.25";
export const lineHeight150 = "1.5";
export const headingH1 = "500 60px/125% IBM Plex Sans";
export const headingH2 = "500 40px/125% IBM Plex Sans";
export const headingH3 = "500 32px/125% IBM Plex Sans";
export const headingH4 = "600 28px/125% IBM Plex Sans";
export const headingH5 = "600 22px/125% IBM Plex Sans";
export const headingH6 = "600 18px/125% IBM Plex Sans";
export const letterSpacing0 = "-0.03em";
export const letterSpacing1 = "-0.02em";
export const letterSpacing2 = "-0.01em";
export const letterSpacing3 = "0";
export const letterSpacing4 = "0.01em";
export const letterSpacing5 = "0.02em";
export const letterSpacing6 = "0.15em";
export const paragraphSpacing0 = 0;
export const paragraphSpacing1 = 12;
export const paragraphSpacing2 = 24;
export const radiusDefault = "0.375rem";
export const radiusPill = "100rem";
export const radiusLarge = "0.75rem";
export const sizeSm = "768px";
export const sizeMd = "1044px";
export const sizeLg = "1236px";
export const sizeXl = "1464px";
export const spacing0 = "0";
export const spacing1 = "6px";
export const spacing2 = "12px";
export const spacing3 = "18px";
export const spacing4 = "24px";
export const spacing5 = "36px";
export const spacing6 = "48px";
export const spacing7 = "96px";
export const textBody = "400 22px/150% IBM Plex Sans";
export const textLead = "400 32px/150% IBM Plex Sans";
export const textLarge = "400 36px/150% IBM Plex Sans";
export const textSmall = "400 18px/150% IBM Plex Sans";
export const textXSmall = "400 14px/150% IBM Plex Sans";
export const textEyebrow = "400 14px/150% IBM Plex Mono";
export const textTimeStamp = "400 14px/150% IBM Plex Sans";
export const textCaption = "400 18px/150% IBM Plex Sans";
export const textButtonLabel = "400 18px/100% IBM Plex Sans";
export const textCtaLink = "600 22px/150% IBM Plex Sans";
export const textHomeLead = "400 36px/150% IBM Plex Sans";
export const textLogoType = "600 18px/125% IBM Plex Sans Condensed";
export const textSectionHeadingLarge = "600 22px/125% IBM Plex Sans Condensed";
export const textSectionHeadingSmall = "600 18px/125% IBM Plex Sans Condensed";
export const textCaseNone = "none";
export const textCaseUppercase = "uppercase";
export const textDecorationNone = "none";
export const textDecorationUnderline = "underline";