import { alpha } from "@theme-ui/color"

export const linkGlobals = {
  color: "text",
  textDecoration: "underline",
  textDecorationColor: alpha("text", 0.2),
  textUnderlineOffset: "0.25em",
  textDecorationThickness: "0.125em",
  // transition: "all 0.2s ease-in-out",

  "&:hover": {
    color: "primary",
    textDecorationColor: alpha("primary", 0.5),
    // transition: "all 0.2s ease-in-out",
  },

  // "&:link, &:visited, &:hover, &:active, &:focus": {
  //   transition: "all 0.2s ease-in-out",
  // },
}
